import './styles.scss';
import React from "react";
import { ReactComponent as LogoImage } from '../../../images/logo_inv.svg';
import { ReactComponent as VerticalLogoImage } from '../../../images/logo_inv_v.svg';
import { ReactComponent as CommarchImage } from '../../../images/logo_c_e-uzywam.svg';

export function Footer() {
    return (
        <footer id="footer" className="bg-purple">
            <div className="container">
                <div className="footer">
                    <div className="footer_item">
                        <div className="footer_item-logo">
                            <LogoImage />
                        </div>
                    </div>
                    <div className="footer_item">
                        <div className="footer_item-vertical-logo">
                            <VerticalLogoImage />
                        </div>
                    </div>
                    <div className="footer_item footer_item_banner">
                        <div className="footer_item_banner-commarch">
                            <CommarchImage />
                        </div>
                        <div className="footer_item_banner-iksiegowosc24">
                            <a href="https://www.iksiegowosc24.pl/biura-rachunkowe/slaskie/czestochowa/renata-karwala-biuro-finansowo-ksiegowe-erka" 
                               target="_blank" 
                               title="Biuro polecane przez iKsiegowosc24" rel="noreferrer">
                               <img src="https://www.iksiegowosc24.pl/panel/img/baneryComarch/biuroPolecane/150x50.png" alt="Biuro polecane przez iKsiegowosc24" width="150" height="50" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
