import "./styles.scss";
import React from "react";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Nav, NavItem, Row, Tab } from "react-bootstrap";
import { useAppSelector } from "../../../store";
import { ConfigurableComponent } from "../../../store/configuration/types";
import { scrollToPosition } from "../../shared";
import { Dialog } from "../../shared/dialog";

interface PolicyDetails {
    code: string;
    title: string;
    paragraphs: ParagraphDetails[];
}

interface TokenDetails {
    mail?: string;
}

interface DialogDetails {
    title: string;
    token?: TokenDetails;
    policies: PolicyDetails[];
}

interface DialogState {
    visible: boolean;
    position?: number;
}

interface PoliciesDetails {
    button: string;
    cta: string;
    notice: string;
    dialog: DialogDetails;
}

interface ParagraphDetails {
    text: string;
    paragraphs?: ParagraphDetails[];
}

export function Policies() {
    const storage = window.localStorage;
    const [collapsed, setCollapsed] = useState(false);
    const [dialog, setDialog] = useState<DialogState>({
        visible: false,
    });
    const [consent, setConsent] = useState(
        localStorage.getItem('cookie-consent') === 'true'
    );

    function handleShow(event: any) {
        event.preventDefault();
        setDialog({ visible: true, position: window.scrollY });
    }

    function handleHide() {
        setDialog({ visible: false, position: undefined });

        if (dialog.position)
        {
            scrollToPosition(dialog.position);
        }
    }

    const handleScroll = useCallback(() => {
        const v = window.scrollY > 50;
        if (v !== collapsed) setCollapsed(v);
      }, [collapsed]);

    function setCookieConsent() {
        setConsent(true);
        storage.setItem('cookie-consent', 'true');
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [handleScroll]);

    const policies: ConfigurableComponent<any> | undefined = 
    useAppSelector(x => 
        Object.values(x.configuration.components || {})
        .find(x => x.type === 'policies' && x.code === 'policies')
    );

    if (consent || !policies?.details || !isPoliciesDetails(policies.details)) {
        return null;
    }

    return (
        <div className={`policies ${collapsed ? "policies-collapsed" : ""}`}>
            <div className="policies_notice">
                <div className="policies_notice_text">
                    { renderNotice(policies.details, handleShow) }
                    { renderDialog(policies.details.dialog, dialog, handleHide) }
                </div>
                <Button className="policies_notice_action" 
                        onClick={ setCookieConsent }
                        bsStyle="primary" bsSize="small">{policies.details.button}
                </Button>
            </div>
        </div>
    );
}

function renderNotice(details: PoliciesDetails, handleShow: (event: any) => void) {
    return (details.notice.split('%') || []).map((x, index) => {
        if (x === 'cta') {
            return (
                <a key={ index } href="#page" onClick={ event => { handleShow(event); }}>
                    { details.cta }
                </a>
            );
        }

        return (<span key={ index }>{ x }</span>);
    });
}

function renderDialog(dialogDetails: DialogDetails, dialog: DialogState,  handleHide: () => void) {
    return (
        <Dialog id={ 'policies_dialog' }
                className="policies_dialog"
                closeButton={ true }
                title={ dialogDetails.title }
                onHide={ handleHide }
                show={ dialog.visible }>
                { renderDialogContent(dialogDetails)  }
        </Dialog>
    );
}

function renderDialogContent(dialogDetails: DialogDetails) {
    const items = (dialogDetails.policies || []).filter(isPolicyDetails);
    if (items.length === 0)
    {
        return null;
    }
    else 
    {
        return (
            <Tab.Container id='policies_dialog_policies'
                           className="policies_dialog_policies"
                           defaultActiveKey={ getDefaultPolicyKey(items) }>
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav bsStyle="tabs">
                            { items.map(renderPolicyHeader) }
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content animation>
                            { 
                                items.map(x => {
                                    return (
                                        <Tab.Pane key={ x.code }
                                                  eventKey={ x.code }>
                                            { renderParagraphs(x.paragraphs, dialogDetails.token) }
                                        </Tab.Pane>
                                    );
                                })
                            }
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        );
    }
}

function getDefaultPolicyKey(policies: PolicyDetails[]) {
    if (policies && policies.length > 0) {
        return policies[0].code;
    }

    return undefined;
}

function renderPolicyHeader(policy: PolicyDetails) {
    return (
        <NavItem key={ policy.code }
                 eventKey={ policy.code }>
            <span title={ policy.title }>{ policy.title }</span>
        </NavItem>
    );
}

function renderText(text: string, token?: TokenDetails) {
    if (!token || !token.mail) {
        return (<span>{ text }</span>);
    }

    return (text.split('%') || []).map((x, index) => {
        if (x === 'mail') {
            return (
                <a key={ index } href={`mailto:${token.mail}`}>
                    { token.mail }
                </a>
            );
        }

        return (<span key={ index }>{ x }</span>);
    });
}

function renderParagraphs(paragraphs?: ParagraphDetails[], token?: TokenDetails): JSX.Element | null {
    const items = (paragraphs || []).filter(isParagraphDetails);
    if (items.length === 0)
    {
        return null;
    }
    else {
        return (
            <ol>
                { 
                    items.map((x, index) => {
                        return (
                            <li key={ index }>
                                { renderText(x.text, token) }
                                { renderParagraphs(x.paragraphs, token) }
                            </li>
                        );
                    }) 
                }
            </ol>
        );
    }
}

function isPoliciesDetails(x?: any): x is PoliciesDetails {
    return !!x && !!x.notice && typeof x.notice === "string" && 
           !!x.cta && typeof x.cta === "string" && 
           !!x.button && typeof x.button === "string" && !!x.dialog && isDialogDetails(x.dialog);
}

function isDialogDetails(x?: any): x is DialogDetails {
    return !!x && !!x.title && typeof x.title === "string" && 
           (!x.token || isTokenDetails(x.token)) &&
           !!x.policies && Array.isArray(x.policies) && x.policies.some(isPolicyDetails);
}

function isTokenDetails(x?: any): x is TokenDetails {
    return !!x && (!x.mail || typeof x.mail === "string");
}

function isPolicyDetails(x?: any): x is PolicyDetails {
    return !!x && !!x.code && typeof x.code === "string" && !!x.title && typeof x.title === "string" &&
           !!x.paragraphs && Array.isArray(x.paragraphs) && x.paragraphs.some(isParagraphDetails);
}

function isParagraphDetails(x?: any): x is ParagraphDetails {
    return !!x && !!x.text && typeof x.text === "string" && 
           (!!!x.paragraphs || (Array.isArray(x.paragraphs) && x.paragraphs.some(isParagraphDetails)));
}