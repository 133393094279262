import './styles.scss';
import React, { useCallback, useEffect, useReducer } from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap/lib';
import { ReactComponent as LogoImage } from '../../images/logo.svg';
import { useAppSelector } from '../../store';
import { ConfigurableComponent } from '../../store/configuration/types';
import { shallowEqual } from 'react-redux';
import { scrollToElement } from '../shared';
import { compareConfigurableComponents } from '../../store/configuration/helpers';

interface MenuState {
  expanded: boolean;
  collapsed: boolean;
  currentPosition?: number;
}

interface MenuDetails {
  shortName: string;
}

type Action = 'scroll' | 'select' | 'toggle';

export function Menu() {
  const navItems = useAppSelector(x =>
    Object.values(x.configuration.components || {})
    .filter(c => c.type === 'section' && 
      isMenuDetails(c.details))
    .sort(compareConfigurableComponents), 
    shallowEqual) as Array<ConfigurableComponent<MenuDetails>>;

  const [state, dispatch] = useReducer(reducer, {
    expanded: false, collapsed: false,
  });

  const handleScroll = useCallback(() => {
    dispatch('scroll');
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (navItems.length === 0) {
    return null;
  }

  return (
    <Navbar
      fixedTop
      expanded={state.expanded}
      onToggle={() => dispatch('toggle')}
      onSelect={() => dispatch('select')}
      className={`menu ${getMenuClassName(state.collapsed)}`}
    >
      <Navbar.Header>
        <Navbar.Brand className="menu_brand">
          <LogoImage />
        </Navbar.Brand>
        <Navbar.Toggle className="menu_toggle" />
      </Navbar.Header>
      <Navbar.Collapse className="menu_nav">
        <Nav className="animate__animated animate__fadeIn" style={{animationDuration: "0.7s"}} pullRight>{renderNavItems(navItems)}</Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

function isMenuDetails(x?: MenuDetails): x is MenuDetails {
  return !!x && !!x.shortName;
}

function getMenuClassName(collapsed: boolean) {
  return collapsed ? 'menu-collapsed' : '';
}

function reducer(state: MenuState, action: Action): MenuState {
  switch (action) {
    case 'select':
      return {
        ...state,
        expanded: false,
        currentPosition: undefined,
      };

    case 'toggle':
      return {
        ...state,
        expanded: !state.expanded,
        currentPosition: state.expanded ? undefined : window.scrollY,
      };

    case 'scroll':
      let changed =
        state.currentPosition &&
        Math.abs(window.scrollY - state.currentPosition) > 50;

      return {
        ...state,
        collapsed: window.scrollY > 50,
        expanded: changed ? false : state.expanded,
        currentPosition: changed ? undefined : state.currentPosition,
      };
  }
}

function renderNavItems(navItems: Array<ConfigurableComponent<MenuDetails>>) {
  return (navItems || []).map((x) => {
    return (
      <NavItem
        key={x.code}
        eventKey={1}
        href={`#${x.code}`}
        onClick={() => scrollToElement(x.code, -59)}
      >
        {x.details?.shortName}
      </NavItem>
    );
  });
}
