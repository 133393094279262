import { animateScroll as scroll, scroller } from "react-scroll";

export function scrollToElement(targetId: string, offset = 0) {
    scroller.scrollTo(targetId, {
        duration: 900,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: offset
    });
}

export function scrollToPosition(position: number) {
    scroll.scrollTo(position, {
        duration: 0,
        delay: 0
    });
}