import "./styles.scss";
import React, { PropsWithChildren, Suspense } from "react";
import { Loader } from "../loader";

export enum SectionType {
    Light,
    Lighter,
    Primary
}

interface SectionProps {
    code: string;
    type: SectionType;
    details?: SectionDetails;
}

export interface SectionDetails {
    name: string;
    description?: string;
}

export function isSectionDetails(x?: SectionDetails): x is SectionDetails {
    return !!x && !!x.name && typeof x.name === "string" && 
           (typeof x.description === "string" || typeof x.description === "undefined");
}

export function Section(props: PropsWithChildren<SectionProps>) {    
    if (!props.details) {
        return null;
    }

    return (
        <section id={ props.code } className={ `section text-center ${resolveClassName(props.type)}` }>
            <div className="container">
                <div>
                    <h2>{ props.details.name }</h2>
                    <hr className={ resolveHrClassName(props.type) }/>
                    <p>{ props.details.description }</p>
                </div>
                <Suspense fallback={<Loader />}>
                    { props.children }
                </Suspense>
            </div>
        </section>
    );
}

function resolveClassName(type: SectionType): string {
    switch (type) {
        case SectionType.Light:
            return "bg-light";
        case SectionType.Lighter:
            return "bg-lighter";
        case SectionType.Primary:
            return "bg-primary";
    }
}

function resolveHrClassName(type: SectionType): string {
    switch (type) {
        case SectionType.Light:
        case SectionType.Lighter:
            return "primary";
        case SectionType.Primary:
            return "light";
    }
}