import React from "react";
import { PropsWithChildren } from "react";
import LoadingOverlay from "react-loading-overlay";

interface LoaderProps {
    active?: boolean;
    text?: string;
    className?: string;
}

export function Loader(props: PropsWithChildren<LoaderProps>) {
    return (
        <LoadingOverlay
            text={props.text}
            className={props.className}
            active={props.active ?? true}
            styles={{
                overlay: (base: any) => ({
                    ...base,
                    background: "rgba(0, 0, 0, 0)",
                }),
            }}
            spinner
        >
            {props.children}
        </LoadingOverlay>
    );
}