/* eslint-disable jsx-a11y/anchor-is-valid */
import "./styles.scss";
import React, { useCallback, useEffect, useState } from "react";
import { scrollToElement } from "..";

interface ScrollToTopProps {
    rootId: string;
}

export function ScrollToTop(props: ScrollToTopProps) {
    const [visible, setVisible] = useState(false);

    const handleScroll = useCallback(() => {
        const v = window.scrollY > 200;
        if (v !== visible) setVisible(v);
    }, [visible]);
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <div className={ `scroll-to-top ${visible ? "scroll-to-top-visible" : ""}` }>
            <a onClick={ () => scrollToElement(props.rootId) }>
                <i className="fa fa-angle-up"></i>
            </a >
        </div>
    );
}
