import { createAsyncThunk } from "@reduxjs/toolkit"
import { Configuration } from "./types"

export const fetchData = createAsyncThunk<
  Configuration
>('CONFIGURATION/fetchData',
  async () => {
    const response = await fetch(
      `/api/configuration`
    );
    return (await response.json()) as Configuration;
  }
)