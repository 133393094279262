import './styles.scss';
import React, { PropsWithChildren } from 'react';
import { Modal, Button } from 'react-bootstrap';


interface DialogProps {
    title: string;
    onHide: () => void;
    id?: string;
    show?: boolean;
    className?: string;
    closeButton?: boolean;
    icon?: React.ReactNode;
}

export function Dialog(props: PropsWithChildren<DialogProps>) {
    return (
        <Modal id={ props.id } 
               animation={ false }
               show={ props.show }
               onHide={ props.onHide }
               className={ props.className + " animate__animated animate__bounceIn " }> 
            <Modal.Header 
                className="bg-primary"
                closeButton={ false }>
                <div className="container">
                    { props.icon }
                    <Modal.Title>
                        { props.title }
                    </Modal.Title>
                    { renderCloseButton(props) }
                </div>
            </Modal.Header>
            <Modal.Body>
            <div className="container">
                { props.children }
            </div>
            </Modal.Body>
        </Modal>
    );
}

function renderCloseButton(props: DialogProps) {
    if (!props.closeButton) {
        return null;
    }

    return (
        <Button 
            type="button" className="close" 
            onClick={ props.onHide } 
            hidden={ props.closeButton }>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
        </Button>
    );
}