import "./styles.scss";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../../store";
import { shallowEqual } from "react-redux";
import { Carousel } from "react-bootstrap/lib";

interface HeaderDetails {
  code: typeof HeaderCodes[number];
  title?: string;
  subtitle?: string;
}

const HeaderCodes = ["welcome", "accounting", "businessplan"] as const;

function isHeaderDetails(x?: HeaderDetails): x is HeaderDetails {
  return !!x && (!x.title || typeof x.title === 'string') && 
    (!x.subtitle || typeof x.subtitle === 'string') &&
    !!x.code && HeaderCodes.some(v => v === x.code);
}

export function Header() {
  const headerRef = useRef<HTMLElement>(null);
  const [visible, setVisible] = useState(false);

  const handleScroll = useCallback(() => {
    const v = window.scrollY < 50;
    if (v !== visible) setVisible(v);
  }, [visible]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const headerDetails: Record<string, HeaderDetails> = 
    useAppSelector(x => {
      const header =  Object.values(x.configuration.components || {})
        .find(x => x.type === 'header' && x.code === 'header');
      
      return (header?.details ?? []).reduce(
        (result: Record<string, HeaderDetails>, value: any) => {
          if (isHeaderDetails(value)) {
            return { ...result, [value.code]: value };
          }
          return result;
        }, {});
    }, shallowEqual);

  const headerCodes = useMemo(() => 
    Object.keys(headerDetails), [headerDetails]
  );

  useEffect(() => {
    if (headerCodes.length > 0) {
      setVisible(true);
    }
  }, [headerCodes]);

  return (
    <header id="header" ref={headerRef} className={'header header-welcome ' + 
      (headerCodes.length > 0 ? '' : 'header-initial')
    }>
      <Carousel 
        className={visible ? '' : 'carousel-collapsed'}
        onSelect={(index: any) => {
          if (headerRef.current) {
            if (index >= 0 && index < headerCodes.length) {
              headerCodes.forEach(code => {
                headerRef.current?.classList.remove(`header-${code}`)
              })
              headerRef.current?.classList.add(`header-${headerCodes[index]}`)
            }
          }
        }}
        controls={ true } 
        pauseOnHover={ false } 
        indicators={ Object.keys(headerDetails).length > 0 }>
          { renderCarouselItems(headerDetails['welcome'] ?? { code: 'welcome' }) }
          { renderCarouselItems(...Object.values(headerDetails).filter(x => x.code !== 'welcome')) }
      </Carousel>
    </header>
  );
}

function renderCarouselItems(...headerDetails: HeaderDetails[]) {
  return headerDetails.map(item => {
    return (
      <Carousel.Item key={ item.code } className={ `item-${item.code}` }>
        <div className="item-inner">
          <div className="container text-center">
            <h1>
              { item.title }
            </h1>
            <h3>
              { item.subtitle }
            </h3>
          </div>
        </div>
      </Carousel.Item>
    )}
  );
}
