import React, { useEffect } from "react";
import "./App.scss";
import { fetchData } from "./store/configuration/actions";
import { Menu } from "./components/menu";
import { Home } from "./components/home";
import { useAppDispatch, useAppSelector } from "./store";
import { Loader } from "./components/shared/loader";

function App() {
  const dispatch = useAppDispatch();
  const isRequesting = useAppSelector((x) => x.configuration.isRequesting);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  return (
    <div>
      <Loader active={isRequesting}>
        <Menu />
        <Home />
      </Loader>
    </div>
  );
}

export default App;

/*<Menu /> */
