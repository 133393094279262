import React from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../store";
import { compareConfigurableComponents } from "../../store/configuration/helpers";
import { ConfigurableComponent } from "../../store/configuration/types";
import { ScrollToTop } from "../shared/scrollToTop";
import { isSectionDetails, Section, SectionDetails, SectionType } from "../shared/section";
import { Footer } from "./footer";
import { Header } from "./header";
import { Policies } from "./policies";
import { Summary } from "./summary";

export function Home() {
    const sections = useAppSelector(x =>
        Object.values(x.configuration.components || {})
        .filter(c => c.type === 'section' && 
            isSectionDetails(c.details))
        .sort(compareConfigurableComponents), 
        shallowEqual) as Array<ConfigurableComponent<SectionDetails>>;
        
    return (
        <div id="home">
            <Header />  
            { (sections ?? []).map(section => renderSection(section)) } 
            <Footer />
            <Summary />
            <Policies />
            <ScrollToTop rootId="home" />
        </div>
    );
}

function renderSection(section: ConfigurableComponent<SectionDetails>) {
    switch (section.code) {
        case 'about-us':
            return (
                <Section 
                    key={section.code}
                    code={section.code} 
                    details={section.details} 
                    type={SectionType.Primary}
                >
                    <AboutUs details={section.details}/>
                </Section>
            );

        case 'offer':
            return (
                <Section 
                    key={section.code}
                    code={section.code}
                    details={section.details} 
                    type={SectionType.Light}
                >
                    <Offer details={section.details}/>
                </Section>
            );

        case 'our-team':
            return (
                <Section 
                    key={section.code}
                    code={section.code}
                    details={section.details} 
                    type={SectionType.Light}
                >
                    <OurTeam details={section.details}/>
                </Section>
            );

        case 'benefits':
            return (
                <Section 
                    key={section.code}
                    code={section.code}
                    details={section.details} 
                    type={SectionType.Lighter}
                >
                    <Benefits details={section.details}/>
                </Section>
            );

        case 'contact-us':
            return (
                <Section 
                    key={section.code}
                    code={section.code}
                    details={section.details} 
                    type={SectionType.Primary}
                >
                    <ContactUs details={section.details}/>
                </Section>
            );
    }
}

const Offer = React.lazy(() => import(/* webpackChunkName: 'pl.erka.offer' */ './offer'));
const AboutUs = React.lazy(() => import(/* webpackChunkName: 'pl.erka.aboutUs' */ './aboutUs'));
const OurTeam = React.lazy(() => import(/* webpackChunkName: 'pl.erka.ourTeam' */ './ourTeam'));
const Benefits = React.lazy(() => import(/* webpackChunkName: 'pl.erka.benefits' */ './benefits'));
const ContactUs = React.lazy(() => import(/* webpackChunkName: 'pl.erka.contactUs' */ './contactUs'));
