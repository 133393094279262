import { createSlice } from "@reduxjs/toolkit"
import { fetchData } from "./actions"
import { ConfigurationState } from "./types"

const initialState: ConfigurationState = {
  isRequesting: true,
}

const configurationSlice = createSlice({
  name: 'configuraton',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchData.pending, (state) => {
      return {
        ...state,
        isRequesting: true,
      }
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      return {
        ...state,
        isRequesting: false,
        ...action.payload,
      }
    })
  }
})

export const configurationReducer = configurationSlice.reducer;